<template>
    <div class="dashboard-container"><p>Home to a future dashboard.</p></div>
</template>

<script>
import { metaTitle } from '../../../../constant/data'
/** This component contains the code for the admin panel dashboard */
export default {
  name: 'Dashboard',
  metaInfo () {
    return {
      title: metaTitle.adminDashboard
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-container {
  display: flex;
  height: 300px;
  background: var(--primary);
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  border-radius: 4px;
}
</style>
